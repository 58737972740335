import { createMuiTheme, ThemeProvider } from "@material-ui/core";
import "./App.css";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Logo from './logo.svg';

const theme = createMuiTheme({
    palette: {
        primary: { main: "#03a9f4", contrastText: "#ffffff" },
    },
    typography: {
        fontFamily: "Montserrat, Arial, Roboto",
    },

    overrides: {
        MuiButton: {
            raisedPrimary: {
                color: "white",
            },
        },
    },
});

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: "100%", // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    logo: {
      width: '120px',
      height: '120px',
      marginBottom: '20px'
    }
}));

function App() {
  const classes = useStyles();

    return (
        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <div className={classes.paper}>
                    <img className={classes.logo} src={Logo} alt="logo iqcode" />
                    <Typography component="h1" variant="h5">
                      Panel klienta iqcode
                    </Typography>
                    <form className={classes.form} noValidate>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Adres e-mail"
                            name="email"
                            autoComplete="email"
                            autoFocus
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Hasło"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                        />
                        <FormControlLabel
                            control={
                                <Checkbox value="remember" color="primary" />
                            }
                            label="Zapamiętaj poświadczenia"
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                        >
                          Zaloguj się
                        </Button>
                        <Grid container>
                            <Grid item xs>
                                <Link href="mailto:support@iqcode.pl" variant="body2">
                                  Wsparcie techniczne
                                </Link>
                            </Grid>
                            <Grid item>
                                <Link href="https://iqcode.pl" variant="body2">
                                    {"Wróć do iqcode.pl"}
                                </Link>
                            </Grid>
                        </Grid>
                    </form>
                </div>
            </Container>
        </ThemeProvider>
    );
}

export default App;
